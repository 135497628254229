// function s2ab(s) {
//   var buf = new ArrayBuffer(s.length);
//   var view = new Uint8Array(buf);
//   for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//   return buf;
// }
import axios from 'axios';

const exportRequest = function (requestUrl, headers = { apiKey: 1 }) {
  try {
    axios({
      url: requestUrl,
      method: 'GET',
      responseType: 'blob',
      headers
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const filename = response.headers['content-disposition']
          .split(';')
          .find((n) => n.includes('filename='))
          .replace('filename=', '')
          .trim();
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((errors) => {
        return errors;
        // console.log(errors);
      });
  } catch (e) {
    return e;
    // console.log(e);
  }
};

const importFile = (requestUrl, file, headers = { 'Content-Type': 'multipart/form-data', apiKey: 1 }) => {
  return new Promise((resolve, reject) => {
    try {
      let formData = new FormData();
      formData.append('file', file);
      axios({
        url: requestUrl,
        data: formData,
        method: 'POST',
        headers
      })
        .then((response) => resolve(response))
        .catch((errors) => reject(errors));
    } catch (error) {
      resolve(error);
    }
  });
};

export { exportRequest, importFile };
